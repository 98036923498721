import { zrpc } from "@/lib/zrpc";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";
import { ConsumerMe } from "utils/trpc";

export function LoggedInConsumerLabel(props: { consumer: ConsumerMe }) {
  const { mutateAsync: logout } = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/internal/auth/logout"
  );

  const interfacesTheme = useInterfacesTheme();

  const handleLogout = async () => {
    try {
      await logout({});
    } catch (error) {
      console.error("Failed to logout:", error);
    } finally {
      const { pathname } = new URL(window.location.href);
      const isOnRootPath = pathname === "/";
      /**
       * Historically, we used the `router.replace("/")` here.
       * That did not work, since Next.js will NOT re-mount the component
       * if we navigate to the same page we are currently on.
       *
       * https://nextjs.org/docs/pages/api-reference/functions/use-router#resetting-state-after-navigation
       *
       * We want to force the browser to reload the page to ensure all the state is reset.
       */
      if (isOnRootPath) {
        /**
         * We are not using `window.location.href = "/"` because we observed the page sometimes wont reload if
         * the user is already on the "/" path.
         */
        window.location.reload();
      } else {
        window.location.href = "/";
      }
    }
  };

  return (
    <div
      className={cn(
        "mt-[50px] flex justify-center pb-[50px] pt-[5px] font-sans text-sm font-normal [&_a]:transition",
        interfacesTheme
          ? "text-foreground [&_a:hover]:text-primary [&_a]:text-foreground-subtle"
          : "text-zi-secondaryText [&_a:hover]:text-zi-primary [&_a]:text-zi-secondaryText"
      )}
    >
      Logged in as {`${props.consumer.email}`} &nbsp;{" "}
      <a
        href="#"
        onClick={() => {
          void handleLogout();
        }}
        data-testid="bottom-page-logout"
      >
        Log out
      </a>
    </div>
  );
}
