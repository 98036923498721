import { z } from "zod";

/**
 * The schemas live in a separate file to allow other components to import them without causing a circular dependency.
 * For example, the `packages/service/src/block-system/brickz/components/ui/LinkedRecordsInput.tsx` has a dependency on this file.
 */

export const emailFieldValueSchema = z.string();

export const numberFieldValueSchema = z.number();

export const stringFieldValueSchema = z.string();

export const multipleStringFieldValueSchema = z
  .array(stringFieldValueSchema)
  .nonempty();

export const textFieldValueSchema = z.string();

export const decimalFieldValueSchema = z.number();

export const phoneNumberFieldValueSchema = z.string();

export const currencyFieldValueSchema = z.number();

export const DateFieldTypeSchema = z.string().refine(
  (value) => {
    /**
     * This is a sanity check to ensure that the date is valid.
     */
    return !Number.isNaN(new Date(value).getTime());
  },
  { message: "Invalid date" }
);

export const labeledStringFieldValueSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const multipleLabeledStringFieldValueSchema = z
  .array(labeledStringFieldValueSchema)
  .nonempty();

export const aiFormulaFieldValueSchema = z.object({
  value: z.string(),
});

export const linkedRecordFieldValueSchema = z.object({
  label: z.string(),
  source: z.string(),
});

export const multipleLinkedRecordFieldValueSchema = z
  .array(linkedRecordFieldValueSchema)
  .nonempty();

export const linkFieldValueSchema = z.object({
  link: z.string().url(),
  text: z.string(),
});

export const multipleLinkFieldValueSchema = z
  .array(linkFieldValueSchema)
  .nonempty();
