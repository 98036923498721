import { ReactNode, createContext, useContext, useMemo } from "react";
import { trpc } from "utils/trpc";
import { at } from "lodash";
import type { SplitFeatureFlagValue } from "server/schemas/split";
import type { SplitFlag } from "server/services/split/types";

type Flags = Record<SplitFlag, boolean>;

const SplitContext = createContext<Flags | null>(null);

export function SplitProvider({
  children,
  projectId,
  fallback,
  initialSplit,
}: {
  children: ReactNode;
  projectId: string | undefined;
  fallback: ReactNode;
  /**
   * To make testing easier.
   */
  initialSplit?: Partial<Record<SplitFlag, SplitFeatureFlagValue>>;
}) {
  /**
   * We have to use a client-side fetch here to make Playwright feature-flag
   * mocks work.
   *
   * Playwright is unable to intercept backend initialized-requests. If we were
   * to use `useSuspenseQuery` here, then the request would be initialized by
   * the backend, in places where we SSR the page (for example the published
   * page). Since it is impossible for Playwright to initialize request that are
   * made by the backend, the feature-flag mocks would not work.
   */
  const { data: splitTreatments, isLoading } = trpc.split.check.useQuery(
    {
      projectId,
    },
    {
      throwOnError: false,
      meta: { noToast: true },
      /**
       * These have to stay true for the time being.
       * When page is SSRed, we hydrate the results of this query from the backend.
       *
       * This is problematic for Playwright feature-flag mocks.
       * We have to refetch this query, so the network intercepts to kick in.
       * Otherwise we would not be able to set any mocks for the feature flags.
       *
       * Please note that these settings do NOT influence the loading time of the website.
       * The `isLoading` and the `status === "loading"` will only be true when we initially fetch the feature flags.
       *
       * We would be slowing the _perceived_ loading time, if we were to display the loader on the `isRefetching` flag,
       * but that is not what we are doing.
       */
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      initialData: initialSplit,
      enabled: !initialSplit,
    }
  );

  const flags: Flags = useMemo(() => {
    if (!splitTreatments) return {} as Flags;
    return Object.keys(splitTreatments).reduce((acc, key) => {
      const enabled = splitTreatments[key as SplitFlag] === "on";
      return {
        ...acc,
        [key]: enabled,
      };
    }, {} as Flags);
  }, [splitTreatments]);

  if (isLoading) {
    return <>{fallback}</>;
  }

  return (
    <SplitContext.Provider value={flags}>{children}</SplitContext.Provider>
  );
}

export function useSplitFlags(flags: SplitFlag[]) {
  const context = useContext(SplitContext);
  if (context === null) {
    throw new Error("useSplitFlags must be used within a SplitProvider");
  }
  return at(context, flags);
}

export function useIsFileUploadsDisabled() {
  const [fileUploadsDisabled] = useSplitFlags([
    "temporarily-disable-file-uploads",
  ]);

  return fileUploadsDisabled;
}

export function useIsLoginCustomizationEnabled() {
  const [loginCustomizationFlag] = useSplitFlags([
    "interfaces_login_page_customization_project",
  ]);

  return loginCustomizationFlag;
}
